import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom"

import React from 'react'

import { Ellipsis, Ring, Ripple } from 'react-css-spinners'

import queryString from 'query-string'
import * as httpHelper from '../helpers/http.helper'

export default class Payment extends React.Component {

    constructor(props) {
        super(props)
        console.log("query", this.props.location.search, this.props.match.params)
        this.state = {
            loading: true,
            message: ""
        }

        this.http = httpHelper.http
        this.mercadoPago = null
    }

    getParams() {
        console.log("query LOC", window.location.search)
        let params = queryString.parse(window.location.search)
        return params

        // window.location.host
    }

    initMercadoPago() {
        // window.Mercadopago.setPublishableKey("TEST-72714eda-66a3-4da6-8f21-7ba6f567bcd9")
        this.mercadoPago = new window.MercadoPago('TEST-72714eda-66a3-4da6-8f21-7ba6f567bcd9')
    }

    getQueryParams(url) {
        let qParams = {}
        // create a binding tag to use a property called search
        let anchor = document.createElement('a')
        // assign the href URL of the anchor tag
        anchor.href = url
        // search property returns URL query string
        let qStrings = anchor.search.substring(1)
        let params = qStrings.split('&')
        for (let i = 0; i < params.length; i++) {
            let pair = params[i].split('=')
            qParams[pair[0]] = decodeURIComponent(pair[1])
        }
        return qParams;
    };

    generatedPaymentV1() {
        let params = this.getParams()
        let self = this
        //let identifications = window.Mercadopago.getIdentificationTypes()
        //Object.assign({}, params, { docType: identifications })

        let placeToken = params.placeToken
        let description = params.description
        let isSave = params.isSave
        let userIdMercadoPago = params.userIdMercadoPago

        delete params.placeToken
        delete params.description
        delete params.isSave
        delete params.userIdMercadoPago

        console.log("yosalgo paramsJOOPPP", params)

        if (params.cardId !== undefined) {
            console.log("yosalgo params", params)
            self.createToken({ id: "1617084724722" }, placeToken, description, isSave, userIdMercadoPago)
        } else {
            let bin = params.cardNumber.substring(0, 6)
            window.Mercadopago.getPaymentMethod({
                "bin": bin
            }, function (status, response) {
                if (status == 200) {
                    let paymentMethodId = response[0].id;
                    Object.assign(params, { payment_method_id: paymentMethodId })
                    console.log("paymetdLLL", paymentMethodId)
                    self.createToken(params, placeToken, description, isSave, userIdMercadoPago)
                } else {
                    console.log(`payment method info error: ${response}`);
                }
            })
        }
    }

    async generatedPayment() {
        let params = this.getParams()
        let token = ""
        let payer = {}

        if (params.cardId !== undefined) {
            token = await this.mercadoPago.createCardToken({
                cardId: params.cardId,
                securityCode: params.securityCode,
            })
            payer = {
                type: "customer",
                id: params.userIdMercadoPago
            }
            console.log('token received cardId: ', token.id)
        } else {
            token = await this.mercadoPago.createCardToken({
                securityCode: params.securityCode,
                cardExpirationYear: params.cardExpirationYear,
                cardExpirationMonth: params.cardExpirationMonth,
                cardholderName: params.cardholderName,
                cardNumber: params.cardNumber,
            })
            console.log('token receivedKKKK: ', token.id)
            let bin = params.cardNumber.substring(0, 6)
            let paymentMethodId = await this.mercadoPago.getPaymentMethods({ bin: bin })
            paymentMethodId = paymentMethodId.results[0].id

            Object.assign(params, { payment_method_id: paymentMethodId })
            console.log('payment_method_id receivedKKKK: ', paymentMethodId)
            payer = { email: params.email }
        }

        let data = {
            payment: {
                transaction_amount: parseInt(params.transaction_amount),
                token: token.id,
                description: params.description,
                installments: parseInt(params.installments),
                payment_method_id: params.payment_method_id,
                payer: payer,
                application_fee: params.applicationFee ? parseInt(params.applicationFee) : 0
            },
            placeToken: params.placeToken,
            isSave: params.isSave,
            userIdMercadoPago: params.userIdMercadoPago
        }

        let paramsOption = httpHelper.parseParamsUrlGet({ type: "mercadopago" })
        this.sendPayment(paramsOption, data)
    }

    createToken(params, placeToken, description, isSave, userIdMercadoPago) {
        let self = this
        window.Mercadopago.createToken(params, function (status, response) {
            if (status != 200 && status != 201) {
                console.error("verify filled data", status, response)
            } else {
                let data = {
                    payment: {
                        transaction_amount: parseInt(params.transaction_amount),
                        token: response.id,
                        description: description,
                        installments: parseInt(params.installments),
                        payment_method_id: params.payment_method_id,
                        payer: {
                            email: params.email
                        },
                        application_fee: params.applicationFee ? parseInt(params.applicationFee) : 0
                    },
                    placeToken: placeToken,
                    isSave: isSave,
                    userIdMercadoPago: userIdMercadoPago
                }

                if (!params.applicationFee) {
                    delete params.applicationFee
                }

                console.log("generatedPayment", data)
                let paramsOption = httpHelper.parseParamsUrlGet({ type: "mercadopago" })
                self.sendPayment(paramsOption, data)
            }
        })
    }

    sendPayment(params, data) {
        let parseData = { data: "", success: false }
        this.http.post("?" + params, data).then((response) => {
            console.log("joper sendPayment", response)
            parseData.data = response.data
            parseData.success = true
            window.ReactNativeWebView.postMessage(JSON.stringify(parseData))
            this.setState({ loading: false, message: "Pago realizado" })
        }).catch((error) => {
            console.error("joper sendPayment", error)
            this.setState({ loading: false, message: error.message })
            parseData.data = error.message
            window.ReactNativeWebView.postMessage(JSON.stringify(parseData))
        })
    }

    componentDidMount() {
        this.initMercadoPago()
        this.generatedPayment()
    }

    render() {
        return (
            <div style={{ backgroundColor: 'rgb(0, 0, 0, 0.6)', height: '100%', width: '100%', position: 'absolute' }}>
                {this.state.loading &&
                    <div style={{ top: '50%', position: 'relative' }}>
                        <div style={{ textAlign: 'center' }}>
                            <Ellipsis />
                        </div>


                        <div style={{ textAlign: 'center' }}>
                            <label style={{ color: "#fff" }}>Procesando</label>
                        </div>


                    </div>
                }

                {this.state.message !== "" && <div style={{ top: '50%', position: 'relative' }}>
                    <div style={{ textAlign: 'center' }}>
                        <label style={{ color: "#fff" }}>{this.state.message}</label>
                    </div></div>
                }
            </div>
        )
    }
}

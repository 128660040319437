import axios from 'axios'

export const http = axios.create({
    baseURL: "https://padh3g97wf.execute-api.us-east-1.amazonaws.com/default/yosalgo-procesador-mp/",
    timeout: 5000,
    withCredentials: false
})

export function getHttp() {
    return http
}

export function parseParamsUrlGet(data) {
    return Object.entries(data).map(([key, val]) => `${key}=${val}`).join('&')
}
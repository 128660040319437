import React from 'react'
import logo from './logo.svg'
import './App.css'

import axios from 'axios'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom"

import Payment from './forms/payment'
import AssociatePayment from './forms/associatePayment'


class App extends React.Component {

  constructor(props) {
    super(props)
  }



  render() {

    return (
      <Router>
        <Switch>

          <Route path="/associate-payment" render={(props) => <AssociatePayment {...props} />} />
          <Route path="/" render={(props) => <Payment {...props} />} />
        </Switch>
      </Router>
    )
  }
}

export default App

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom"

import React from 'react'

import { Ellipsis, Ring, Ripple } from 'react-css-spinners'
import { graphql } from 'graphql'
import gql from 'graphql-tag'

import queryString from 'query-string'
import axios from 'axios'

import * as httpHelper from '../helpers/http.helper'
import moment from "moment"

const UP_PLACE = `
    mutation UpdatePlacePayment($placeId: uuid!,$payment_metadata: jsonb) {
        update_place(where: {id: {_eq: $placeId}}, _set: {payment_metadata: $payment_metadata}) {
            affected_rows
        }
    }
`
export default class AssociatePayment extends React.Component {

    constructor(props) {
        super(props)
        console.log("query", this.props.location.search, this.props.match.params)
        this.state = {
            loading: true,
            message: ""
        }

        this.http = httpHelper.http
        this.params = null
    }

    getParams() {
        let params = queryString.parse(this.props.location.search)
        console.log("getParams", params)
        return params
    }

    generatedToken(paramsUrl) {
        let parseData = { placeId: paramsUrl.state, code: paramsUrl.code }
        let params = httpHelper.parseParamsUrlGet({ type: "associate-mercadopago" })
        this.http.post("?" + params, parseData).then((response) => {
            console.log("joper generatedToken", response)
            this.setState({ loading: false, message: "La cuenta se asocio sastifactoriamente" })
        }).catch((error) => {
            console.log("joper generatedToken error", error)
            this.setState({ loading: false, message: "Ocurrio un error al intentar asociar la cuenta" })
        })
    }

    generatedTokenLast(params) {
        console.log("generarted tokenHHHH", params.code)
        let parseData = { data: "", success: false }
        let paymentData = null

        axios({
            headers: { 'accept': 'application/json', 'content-type': 'application/x-www-form-urlencoded' },
            method: 'post',
            url: 'https://api.mercadopago.com/oauth/token',
            withCredentials: false,
            data: {
                client_secret: 'APP_USR-4378117044664673-012322-e71236c74d3a4752e2c22e49040ecec7__LB_LD__-122554589',
                client_ID: '4378117044664673',
                grant_type: 'authorization_code',
                code: params.code,
                redirect_uri: 'https://www.payments.yosalgo.com/associate-payment'
            }
        }).then((response) => {
            console.log("success ", response.data)
            parseData.data = response.data
            parseData.success = true

            window.localStorage.setItem("MP_XVX", JSON.stringify({
                date: moment().format("YYYY-MM-DD"),
                data: parseData.data
            }))

            this.setState({ loading: false, message: "La cuenta se asocio sastifactoriamente" })
            paymentData = [{
                MercadoPago: parseData.data
            }]
            this.updatePlace(paymentData, params.state)
            //window.ReactNativeWebView.postMessage(JSON.stringify(parseData))
        })
            .catch((error) => {
                console.log("ERROR",error.response)
                let mp = window.localStorage.getItem("MP_XVX")
                if (mp !== undefined && mp !== null) {
                    mp = JSON.parse(mp)
                    paymentData = [{
                        MercadoPago: mp.data
                    }]

                    this.updatePlace(paymentData, params.state)

                    let dateTimeX = moment(mp.date).format("YYYY-MM-DD")
                    let daysTranscurrent = moment().diff(dateTimeX, 'days', false)
                    if (daysTranscurrent >= 15) {
                        window.localStorage.removeItem("MP_XVX")
                    }
                } else {
                    this.setState({ loading: false, message: "Ocurrio un error al intentar asociar la cuenta" })
                }

                // window.ReactNativeWebView.postMessage(JSON.stringify(error))
            })

        //window.ReactNativeWebView.postMessage(JSON.stringify(parseData))
    }

    updatePlace(data, placeId) {
        axios.defaults.headers = {
            'Content-Type': 'application/json',
            'x-hasura-admin-secret': '6DVLUGVVSxPxQg8Z*&jFw&#xcDWjqdEWn62hyrh9IWmvJFeaO063m$#1jvT7ztE3FP^KJ3M8V4^%a*9uI0xjx1MvyObblEl#tp5'
        }
        axios.post('https://yosalgo-dev.herokuapp.com/v1/graphql', {
            query: UP_PLACE,
            variables: {
                payment_metadata: data,
                placeId: placeId
            },
        }).then((response) => {
            this.setState({ loading: false, message: "La cuenta se asocio sastifactoriamente" })
        }).catch((error) => {
            this.setState({ loading: false, message: "Ocurrio un error al intentar asociar la cuenta" })
        })
    }

    refreshToken(refreshToken) {

        let parseData = { data: "", success: false }

        axios({
            headers: { 'accept': 'application/json', 'content-type': 'application/x-www-form-urlencoded' },
            method: 'post',
            url: 'https://api.mercadopago.com/oauth/token',
            withCredentials: false,
            data: {
                client_secret: 'APP_USR-4378117044664673-012322-e71236c74d3a4752e2c22e49040ecec7__LB_LD__-122554589',
                client_id: '4378117044664673',
                grant_type: 'refresh_token',
                refresh_token: refreshToken
            }
        }).then(function (response) {
            console.log("success ", response.data)
            parseData.data = response.data
            parseData.success = true

            window.ReactNativeWebView.postMessage(JSON.stringify(parseData))
        })
            .catch(function (error) {
                window.ReactNativeWebView.postMessage(JSON.stringify(error))
            })
    }

    componentDidMount() {
        let data = this.getParams()

        if (document.hasOwnProperty('postMessage')) {
            document.postMessage(JSON.stringify(data), '*')
            console.log('unable to find postMessage d')
        } else if (window.hasOwnProperty('postMessage')) {
            window.postMessage(JSON.stringify(data), '*')
            console.log('unable to find postMessage w')
        } else {
            console.log('unable to find postMessage')
        }

        if (data.refresh_token == undefined) {
            this.generatedToken(data)
        } else {
            this.refreshToken(data.refresh_token)
        }
    }

    render() {
        return (
            <div style={{ backgroundColor: 'rgb(0, 0, 0, 0.6)', height: '100%', width: '100%', position: 'absolute' }}>
                {this.state.loading &&
                    <div style={{ top: '50%', position: 'relative' }}>
                        <div style={{ textAlign: 'center' }}>
                            <Ellipsis />
                        </div>


                        <div style={{ textAlign: 'center' }}>
                            <label style={{ color: "#fff" }}>Procesando</label>
                        </div>


                    </div>
                }

                {this.state.message !== "" && <div style={{ top: '50%', position: 'relative' }}>
                    <div style={{ textAlign: 'center' }}>
                        <label style={{ color: "#fff" }}>{this.state.message}</label>
                    </div></div>
                }

            </div>
        )
    }
}
